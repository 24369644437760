<template>
  <v-card class="mt-n10 moholand-shadow" :loading="loading" :disabled="loading">
    <v-card-title>
      تنظیمات قالب
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-tabs dark background-color="transparent" show-arrows>
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab class="pa-0 ma-auto">
          <v-btn
            class="ma-2"
            elevation="0"
            :color="tab === 0 ? '#FB6D00' : '#a0a0a0'"
            @click="tab = 0"
          >
            عمومی
          </v-btn>
          <v-btn
            class="ma-2"
            elevation="0"
            :color="tab === 1 ? '#FB6D00' : '#a0a0a0'"
            @click="tab = 1"
          >
            فروشگاه
          </v-btn>
          <!-- <v-btn
            class="ma-2"
            elevation="0"
            :color="tab === 2 ? '#FB6D00' : '#a0a0a0'"
            @click="tab = 2"
          >
            وبلاگ
          </v-btn> -->
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-form ref="form">
          <v-tab-item>
            <v-form-base
              :col="{ cols: 12, md: 6 }"
              :model="tab1.model"
              :schema="tab1.schema"
            />
            <multi-input
              class="mt-4"
              v-model="tab1.model.sliders"
              :schema="tab1.slidersSchema"
              title="اسلایدر اصلی"
            />
            <multi-input
              class="mt-4"
              v-model="tab1.model.footer_links"
              :schema="tab1.footersSchema"
              title="لینک های فوتر"
            />
          </v-tab-item>
          <v-tab-item>
            <v-form-base
              :col="{ cols: 12, md: 6 }"
              :model="tab2.model"
              :schema="tab2.schema"
            />
          </v-tab-item>
          <!-- <v-tab-item>
          <v-form-base
            :col="{ cols: 12, md: 6 }"
            :model="tab3.model"
            :schema="tab3.schema"
          />
        </v-tab-item> -->
        </v-form>
      </v-tabs-items>
      <v-card-actions class="mt-10">
        <v-spacer />

        <v-btn color="primary" @click="submit" :loading="loading">
          ثبت
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";
import MultiInput from "../../../../components/MultiInput.vue";
export default {
  components: {
    VFormBase,
    MultiInput,
  },
  data() {
    return {
      rules: Constants.rules,
      loading: false,
      tab: 0,
      tab1: {
        model: {
          logo_id: null,
          namad_one: null,
          namad_two: null,
          top_banner_id: null,
          login_media_id: null,
          banner_url: "",
          copyright: "",
          sliders: [],
          footer_links: [],
          instagram: "",
          youtube: "",
          aparat: "",
          telegram: "",
        },
        schema: {
          logo_id: {
            type: "MyFilePond",
            title: "لوگو سایت",
            col: { cols: 12 },
          },
          namad_one: {
            type: "CustomInput",
            label: "نماد 1",
            class: "ltr",
            col: { cols: 12, md: 6 },
          },
          namad_two: {
            type: "CustomInput",
            label: "نماد 2",
            class: "ltr",
            col: { cols: 12, md: 6 },
          },
          Title1: {
            type: "VFormTitle",
            label: "هدر کمپین",
            col: { cols: 12 },
          },
          top_banner_id: {
            type: "MyFilePond",
            col: { cols: 12 },
          },
          banner_url: {
            type: "CustomInput",
            label: "لینک هدر",
            class: "ltr",
            col: { cols: 12 },
          },
          copyright: {
            type: "CustomInput",
            label: "متن کپی رایت",
            col: { cols: 12 },
          },
          login_media_id: {
            title: "بکگراند صفحه لاگین",
            type: "MyFilePond",
            fileTypes: "image/png, image/gif, image/jpeg",
            col: { cols: 12 },
          },
          socialTitle: {
            type: "VFormTitle",
            label: "شبکه های اجتماعی",
            col: { cols: 12 },
          },
          instagram: {
            type: "CustomInput",
            label: "اینستاگرام",
            class: "ltr",
          },
          youtube: {
            type: "CustomInput",
            label: "یوتیوب",
            class: "ltr",
          },
          aparat: {
            type: "CustomInput",
            label: "آپارات",
            class: "ltr",
          },
          telegram: {
            type: "CustomInput",
            label: "تلگرام",
            class: "ltr",
          },
        },
        slidersSchema: {
          order: {
            type: "CustomInput",
            rules: [Constants.rules.required],
            label: "ترتیب",
            required: true,
            col: { cols: 12, md: 2 },
          },
          media_id: {
            type: "MyFilePond",
            col: { cols: 12, md: 5 },
            withDelete: false,
          },
          url: {
            type: "CustomInput",
            label: "لینک",
            class: "ltr",
            col: { cols: 12, md: 5 },
          },
        },
        footersSchema: {
          name: {
            type: "CustomInput",
            label: "نام",
            rules: [Constants.rules.required],
            col: { cols: 12, md: 3 },
          },
          url: {
            type: "CustomInput",
            label: "لینک",
            class: "ltr",
            col: { cols: 12, md: 3 },
          },
          title: {
            type: "CustomInput",
            label: "سر تیتر گروه",
            rules: [Constants.rules.required],
            col: { cols: 12, md: 3 },
          },
          order: {
            type: "CustomInput",
            inputType: "select",
            items: [
              {
                id: 1,
                name: "اول",
              },
              {
                id: 2,
                name: "دوم",
              },
              {
                id: 3,
                name: "سوم",
              },
              {
                id: 4,
                name: "چهارم",
              },
            ],
            itemValue: "id",
            itemText: "name",
            rules: [Constants.rules.required],
            label: "ستون",
            required: true,
            col: { cols: 12, md: 3 },
          },
        },
      },
      tab2: {
        model: {
          best_seller_id: null,
          offers_url: "",
          icons: [
            {
              title: "",
              media: "",
            },
            {
              title: "",
              media: "",
            },
            {
              title: "",
              media: "",
            },
            {
              title: "",
              media: "",
            },
          ],
        },
        schema: {
          best_seller_id: {
            type: "CustomInput",
            inputType: "select",
            items: [],
            itemValue: "id",
            itemText: "name",
            label: "فروشنده برتر",
            loading: true,
            required: true,
            col: { cols: 12 },
          },
          Title: {
            type: "VFormTitle",
            label: "پیشنهادات شگفت انگیز",
            col: { cols: 12 },
          },
          offers_url: {
            type: "CustomInput",
            label: "لینک مشاهده همه",
            class: "ltr",
            col: { cols: 12 },
          },
          Title2: {
            type: "VFormTitle",
            label: "گزینه های فروش",
            col: { cols: 12 },
          },
          icons: {
            type: "array",
            schema: {
              media_id: {
                type: "MyFilePond",
                title: "تصویر",
                col: { cols: 12, md: 6 },
              },
              title: {
                type: "CustomInput",
                label: "عنوان",
                col: { cols: 12, md: 6 },
              },
            },
            col: { cols: 12 },
          },
        },
      },
      tab3: {
        model: {},
        schema: {},
      },
    };
  },
  created() {
    this.GetList();
    this.getUsers();
  },

  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/settings/index/1", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          this.loading = false;
          formGetPrepare(response, this.tab1.model);
          formGetPrepare(response, this.tab2.model);

          let sliders = response.data[0].sliders
            ? JSON.parse(response.data[0].sliders)
            : [];

          this.tab1.model = {
            ...this.tab1.model,
            logo_id: response.data[0].logo || null,
            login_media_id: response.data[0].login_media || null,
            top_banner_id: response.data[0].top_banner || null,
            sliders:
              response.data[0].sliders_media.map((val, i) => ({
                media_id: val,
                url: sliders[i].url,
                order: sliders[i].order || "",
              })) || [],
            footer_links: response.data[0].footer_links
              ? JSON.parse(response.data[0].footer_links)
              : [],
            ...JSON.parse(response.data[0].social),
          };

          this.tab2.model = {
            ...this.tab2.model,
            icons: response.data[0].icons_media.map((val) => ({
              ...val,
              media_id: val.media,
            })) || [
              {
                title: "",
                media_id: "",
              },
              {
                title: "",
                media_id: "",
              },
              {
                title: "",
                media_id: "",
              },
              {
                title: "",
                media_id: "",
              },
            ],
          };
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getUsers() {
      if (!this.tab2.schema.best_seller_id.loading)
        this.tab2.schema.best_seller_id.loading = true;

      MyAxios.get("/user/list/vendor", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.tab2.schema.best_seller_id.items = response.data.map((val) => ({
            ...val,
            name: val.first_name + " " + val.last_name,
          }));
          this.tab2.schema.best_seller_id.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.tab2.schema.best_seller_id.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        // let data = formPostPrepare({ ...this.tab1.model, ...this.tab2.model });
        let data = new FormData();

        console.log("inside submit", this.tab1.model, this.tab2.model);

        data.append(
          "logo_id",
          this.tab1.model.logo_id?.id
            ? this.tab1.model.logo_id.id
            : this.tab1.model.logo_id
        );
        data.append("namad_one", this.tab1.model.namad_one);
        data.append("namad_two", this.tab1.model.namad_two);
        data.append(
          "top_banner_id",
          this.tab1.model.top_banner_id?.id || this.tab1.model.top_banner_id
        );
        data.append("banner_url", this.tab1.model.banner_url);
        data.append("copyright", this.tab1.model.copyright);
        data.append("offers_url", this.tab2.model.offers_url);
        data.append("copyright", this.tab1.model.copyright);
        if (this.tab2.model.best_seller_id)
          data.append("best_seller_id", this.tab2.model.best_seller_id);
        data.append(
          "login_media_id",
          this.tab1.model.login_media_id?.id || this.tab1.model.login_media_id
        );

        data.append(
          "sliders",
          JSON.stringify(
            this.tab1.model.sliders.map((val) => ({
              media_id: val.media_id.id ? val.media_id.id : val.media_id,
              url: val.url || "",
              order: val.order || "1",
            }))
          )
        );

        data.append(
          "icons",
          JSON.stringify(
            this.tab2.model.icons.map((val) => ({
              title: val.title,
              media_id: val.media_id?.id ? val.media_id?.id : val.media_id,
            }))
          )
        );

        data.append(
          "footer_links",
          JSON.stringify(this.tab1.model.footer_links)
        );

        data.append(
          "social",
          JSON.stringify({
            instagram: this.tab1.model.instagram,
            youtube: this.tab1.model.youtube,
            aparat: this.tab1.model.aparat,
            telegram: this.tab1.model.telegram,
          })
        );

        MyAxios.post("settings/1/update", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "تنظیمات سایت با موفقیت تغییر کرد.",
              type: "success",
            });
            console.log(response);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
            });
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
